import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class MainCatalogItem extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        let { item } = this.props;
        let image = null;
        try {
            let file = require(`../../../../images/catalog/category_${item.id}.png`);
            image = <img src={file}
                             alt={item.title}
                             className="main-catalog-list__item-img"
            />;
        }
        catch (e) {}

        return (
            <Link to={this.props.to ? this.props.to : '/catalog/' + item.slug}
                className="main-catalog-list__item unselectable">
                <div className="main-catalog-list__item-img-wrapper">
                    {image}
                </div>
                <span className="main-catalog-list__item-text">{item.title}</span>
                
            </Link>
        )
    }
}