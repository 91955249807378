import React, {Component} from 'react';
import {StaticRouter as Router, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import PublicLayout from './components/public/layout';
import PrivateLayout from './components/admin/layout';
import UserLayout from './components/private/layout';
import ErrorBoundary from './components/errorBoundary';
import Modal from './components/modal';

import {CategoriesContext} from './services/contexts';
import request from './services/ajaxManager';
import {getMetaForPath} from "./components/public/parts/extraParts";

class App extends Component {
    constructor(props) {
        super(props);

        this.updateFrom = this.updateFrom.bind(this);
        this.checkSizeWindow = this.checkSizeWindow.bind(this);

        this.state = {
            from: null,
            categories: props.data && props.data.categories || [],
            mobileMode: false,
            basket: [],
            favorites: []
        };
    }

    updateFrom(from) {
        this.setState({from: from});
    }

    componentWillUnmount() {
        if (window !== undefined)
            window.removeEventListener('resize', this.checkSizeWindow.bind(this))
    }

    checkSizeWindow() {
        if (window !== undefined && window.innerWidth > 768) {
            this.setState({mobileMode: false})
        } else {
            this.setState({mobileMode: true})
        }
    }

    componentDidMount() {
        this.checkSizeWindow()
        if (window !== undefined)
            window.addEventListener("resize", this.checkSizeWindow.bind(this));

        let additionalData = [
            {
                title: 'Новые товары',
                id: 'new',
                slug: 'new',
                children: []
            }, {
                title: 'Популярные товары',
                id: 'popular',
                slug: 'popular',
                children: []
            }, {
                title: 'Акционные товары',
                id: 'stock',
                slug: 'stock',
                children: []
            }, {
                title: 'Сезонные товары',
                slug: 'season',
                id: 'season',
                children: []
            },
        ]

        let _this = this;

        this.getBasket();
        this.getFavorites();

        if (this.state.categories.length === 0)
            request(
                'product/categories',
                'GET',
                null,
                {},
                (response) => {
                    _this.setState({categories: [...additionalData, ...response]},)
                },
                (err) => console.log(err)
            );
    }

    getBasket = () => {
        let _this = this;

        request(
            'product/basket',
            'GET',
            null,
            {},
            function (response) {
                _this.props.basketUpdate(response);
            },
        );
    }

    getFavorites() {
        let _this = this;

        request(
            'product/favorite',
            'GET',
            null,
            {},
            function (response) {
                _this.setState({favorites: response});
            },
        );
    }

    render() {
        return (
            <div className="App">
                {getMetaForPath(this.props.location.pathname)}
                <ErrorBoundary>
                    <CategoriesContext.Provider value={
                        {
                            categories: this.state.categories,
                            isMobile: this.state.mobileMode
                        }
                    }>
                        <Switch>
                            <Route path="/admin"
                                   component={() => <PrivateLayout updateFrom={this.updateFrom}/>}/>
                            <Route path="/user"
                                   component={() => <UserLayout updateFrom={this.updateFrom}/>}/>
                            <Route path="/" render={() => <PublicLayout from={this.state.from}
                                                                        data={this.props.data}
                                                                        updateFrom={this.updateFrom}/>}/>
                        </Switch>
                    </CategoriesContext.Provider>
                    <Modal/>
                </ErrorBoundary>
            </div>
        );
    }
}

export default withRouter(connect(
    (state) => ({
        loc: state.location
    }),
    dispatch => ({
        basketUpdate: (basket) => {
            dispatch({type: 'BASKET_ADD', payload: basket});
        }
    })
)(App));
