import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import $ from 'jquery';
import {loadableReady} from '@loadable/component'

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import './index_.scss';
import 'font-awesome/css/font-awesome.min.css';
import {default as store} from './reducers/store';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.WEBPACK) require('./index.scss');
if (process.env.WEBPACK) require('./index_.scss');

window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap/dist/js/bootstrap.min.js');

let str = store({...window.APP_STATE});
window.store = str;

let data = (window ? (window.__initialData__ ? window.__initialData__ : null) : null);

loadableReady(() => {
    str.persistor.subscribe(() => {
        /* Hydrate React components when persistor has synced with redux store */
        const {bootstrapped} = str.persistor.getState();

        if (bootstrapped) {
            ReactDOM.hydrate(
                <div>
                    <Provider store={str.store}>
                        <Router>
                            <App data={data}/>
                        </Router>
                    </Provider>
                </div>,
                document.getElementById("root")
            );
        }
    });
});


// ReactDOM.hydrate(
//     <div>
//         <Provider store={str.store}>
//             <Router>
//                 <App data={data}/>
//             </Router>
//         </Provider>
//     </div>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();