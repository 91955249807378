import React, {Component} from 'react';

import request from "../../services/ajaxManager";
import {Helmet} from "react-helmet";
import Breadcrumbs from '../breadcrumbs';

import { TruePagination } from '../truePagination';
import { parsePage } from '../../services/parsePage';
import {Link} from "react-router-dom";


class NotFound extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='news text-center' style={{width: '100%'}}>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <meta name="theme-color" content="#000000"/>
                    <title>404 - Универсал</title>
                    <meta name="description" content="Страницы не существует"/>
                    <meta property="og:description" content="Страницы не существует"/>
                    <meta property="og:title" content="404"/>
                    <meta property="og:url" content="https://universal.tom.ru/404"/>
                </Helmet>
                <h1 className={'text-center'}>404</h1>
                <p className={'text-center'}>Такой страницы не существует!</p>
                <Link className={'text-center'} to={'/'}>Вернуться на главную</Link>
            </div>
        );
    }
}

export default NotFound;
