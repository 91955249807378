import React, {Component} from 'react';
import Breadcrumbs from "../breadcrumbs";

class ConfIngo extends Component {
    render() {
        return (
            <div className='conf-inf'>
                <Breadcrumbs
                    path={[
                        {title: 'Политика конфиденциальности'}
                    ]}/>
                <h1>Принципы и условия обработки персональных данных</h1>
                <div>
                    <p>
                        Политика конфиденциальности сайта
                        Настоящая Политика конфиденциальности персональной информации (далее — Политика) действует в отношении всей информации, которую Сайт (могут получить о пользователе во время использования им сайта. Согласие пользователя на предоставление персональной информации, данное им в соответствии с настоящей Политикой в рамках отношений с одним из лиц, входящих, распространяется на все лица.
                        <br/><br/>
                        Использование
                        <br/>
                        Сайта означает безоговорочное согласие пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями пользователь должен воздержаться от использования Сервисов.
                        <br/>1. Персональная информация пользователей, которую получает и обрабатывает Сайт
                        <br/>1.1. В рамках настоящей Политики под «персональной информацией пользователя» понимаются:
                        <br/>1.1.1. Персональная информация, которую пользователь предоставляет о себе самостоятельно заполнении форм обратной связи, включая персональные данные пользователя.
                        <br/>Обязательная для предоставления Сервисов (оказания услуг) информация помечена специальным образом. Иная информация предоставляется пользователем на его усмотрение.
                        <br/>1.1.2 Данные, которые автоматически передаются в процессе их использования с помощью установленного на устройстве пользователя программного обеспечения, в том числе IP - адрес, информация из cookie, информация о браузере пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой страницы.
                        <br/>1.2. Настоящая Политика применима только к Сайт . Сайт не контролирует и не несет ответственность за сайты третьих лиц, на которые пользователь может перейти по ссылкам, доступным на сайтах
                        <br/>Сайт, в том числе в результатах поиска. На таких сайтах у пользователя может собираться или запрашиваться иная персональная
                        информация, а также могут совершаться иные действия.
                        <br/>1.3. Сайт в общем случае не проверяет достоверность персональной информации, предоставляемой пользователями, и не осуществляет контроль за их дееспособностью. Однако
                        <br/>Сайт исходит из того, что пользователь предоставляет достоверную и достаточную персональную информацию по вопросам, предлагаемым в форме регистрации, и поддерживает эту информацию в актуальном состоянии.
                        <br/>2. Цели сбора и обработки персональной информации пользователей
                        <br/>2.1. Сайт собирает и хранит только те персональные данные, которые необходимы для предоставления и оказания услуг (исполнения соглашений и договоров с пользователем).
                        <br/>2.2. Персональную информацию пользователя
                        <br/>Сайт может использовать в следующих целях:
                        <br/>2.2.1. Идентификация стороны в рамках соглашений и договоров с Сайт
                        <br/>2.2.2. Предоставление пользователю персонализированных услуг;
                        <br/>2.2.3. Связь с пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования Сервисов, оказания услуг, а также обработка запросов и заявок от пользователя;
                        <br/>2.2.4. Улучшение качества, удобства их использования, разработка услуг;
                        <br/>2.2.5. Таргетирование рекламных материалов;
                        <br/>2.2.6. Проведение статистических и иных исследований на основе обезличенных данных.
                        <br/>3. Условия обработки персональной информации пользователя и её передачи третьим лицам
                        <br/>3.1. Сайт хранит персональную информацию пользователей в соответствии с внутренними регламентами конкретных сервисов.
                        <br/>3.2. В отношении персональной информации пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления пользователем информации о себе для общего доступа неограниченному кругу лиц.
                        <br/>При использовании отдельных Сервисов пользователь соглашается с тем, что определённая часть его персональной информации становится общедоступной.
                        <br/>3.3. Сайт вправе передать персональную информацию пользователя третьим лицам в следующих случаях:
                        <br/>3.3.1. Пользователь выразил свое согласие на такие действия;
                        <br/>3.3.2. Передача необходима в рамках использования пользователем определенного Сервиса либо для оказания услуги пользователю;
                        <br/>3.3.3. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;
                        <br/>3.3.4. Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;
                        <br/>3.3.5. В целях обеспечения возможности защиты прав и законных интересов Сайт или третьих лиц в случаях, когда пользователь нарушает Пользовательское соглашение сервисов Сайт.
                        <br/>3.4. При обработке персональных данных пользователей Сайт руководствуется Федеральным законом РФ «О персональных данных».
                        <br/>4. Изменение пользователем персональной информации
                        <br/>4.1. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им персональную информацию и ли её часть, а также параметры её конфиденциальности.
                        <br/>5. Меры, применяемые для защиты персональной информации пользователей
                        <br/>Сайт принимает необходимые и достаточные организационные и технические меры для защиты персональной информации пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.
                        <br/>6. Изменение Политики конфиденциальности. Применимое законодательство
                        <br/>6.1. Сайт имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.
                        <br/>6.2. К настоящей Политике и отношениям между пользователем и Сайт, возникающим в связи с применением Политики конфиденциальности, подлежит применению право Российской Федерации.
                    </p>
                </div>
            </div>
        );
    }
}

export default ConfIngo;