import {createStore} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import reducers from "../reducers";
import storage from 'redux-persist/lib/storage';

const createNoopStorage = () => {
    return {
        getItem(_key) {
            return Promise.resolve(null);
        },
        setItem(_key, value) {
            return Promise.resolve(value);
        },
        removeItem(_key) {
            return Promise.resolve();
        },
    };
};

// const storage =
//     typeof window === "undefined" ? createNoopStorage() : createWebStorage();

const persistConfig = {
    key: 'root',
    storage,
};


const persistedReducer = persistReducer(persistConfig, reducers);

export default () => {
    let store = createStore(
        persistedReducer,
        window !== undefined && window.__REDUX_DEVTOOLS_EXTENSION__ ?
            window.__REDUX_DEVTOOLS_EXTENSION__() : {}
    );

    let persistor = persistStore(store);
    return {store, persistor}
}