import React from "react";
import {Helmet} from "react-helmet";

export function getExtraForPath(path) {
    let result = null;
    switch (path) {
        case '/catalog/samorezy':
            result = <div className={'seoData'}>
                <h2>Продажа саморезов по оптовой цене</h2>
                <p>В интернет-магазине «Универсал» вы можете купить оптом саморезы по дереву, металлу, универсальные.
                    Они изготовлены из закаленной стали, защищены от коррозии. Цвет зависит от способа антикоррозийной
                    обработки.</p>
                <p>Цена на саморезы зависит от следующих факторов:</p>
                <ul>
                    <li>назначение изделия: работы по дереву, металлу, пластику;</li>
                    <li>вид самореза после обработки: оцинкованный, желто- или белопассированный, фосфатированный;</li>
                    <li>размеры: диаметр и длина;</li>
                    <li>конструктивные особенности: наличие прессшайбы, острого или сверлообразного кончика, присутствие
                        и форма потайного бортика.
                    </li>
                </ul>
                <p>Чем больше объем заказанной партии, тем дешевле обходится один саморез из расчета за 1 штуку.
                    Доставка и самовывоз оптовой партии саморезов осуществляется со склада в Томске.</p>
            </div>;
            break;
        case '/catalog/parniki':
            result = <div className={'seoData'}>
                <h2>Материалы изготовления парников для рассады</h2>
                <p>Все парники для рассады, представленные в каталоге компании «Универсал», вы можете купить оптом
                    партиями любых масштабов с доставкой по Томску и области. Для многих садоводов парники
                    привлекательны из-за своей универсальности, мобильности и вариативности габаритов.</p>
                <h3>Опоры и укрывные материалы</h3>
                <p>В изготовлении парников для рассады применяются разные материалы. В качестве опоры могут
                    использоваться пластик и металл.</p>
                <ul>
                    <li>Пластиковые опоры легкие, способны длительное время сохранять первоначальную форму. Однако имеют
                        непродолжительный срок службы, поскольку при перепадах температур пластик может растрескаться.
                    </li>
                    <li>Металлические опоры прочны и долговечны. Но конструкция из металла тяжелее, чем из пластика.
                        Такие опоры требуют дополнительной антикоррозийной обработки.
                    </li>
                </ul>
                <p>В парниках для рассады используется обычно один из двух материалов: полиэтиленовая пленка,
                    агроволокно (Агротекс). Пленка используется довольно часто, считается наиболее доступной по
                    стоимости, отлично крепится на любой каркас. Может использоваться на протяжении 3 сезонов, после
                    этого требует замены.</p>
                <p>Агроволокно (Агротекс) отличается долговечностью и практичностью. За счет уникальной структуры ткани
                    внутри теплицы создаются идеальные условия для выращивания молодых растений. Материал не теряет
                    качественных характеристик на протяжении нескольких лет.</p>
                <p>Подробнее о характеристиках изделий, условиях доставки и скидках вы можете узнать у менеджеров
                    компании по телефонам, указанным на сайте.</p>
            </div>;
            break;
        case '/catalog/plenka-polietilen':
            result = <div className={'seoData'}>
                <h2>Полиэтиленовая рулонная пленка</h2>
                <p>В компании «Универсал» вы можете купить оптом армированную и полиэтиленовую пленку для теплиц любыми
                    партиями с доставкой по Томску и области. Популярность этих материалов объясняется доступной ценой и
                    широким ассортиментом продукции: от тонких полотен до прочных аналогов со стабилизирующими
                    элементами.</p>
                <h3>Выбор пленочных полотен</h3>
                <p>Укрывной материал применяется не только в сельском хозяйстве, очень часто пленка приобретается
                    строительными организациями, складами для разных нужд, в том числе для упаковки товаров.</p>
                <p>Цена на полиэтиленовую пленку в рулонах может зависеть от следующих факторов:</p>
                <ul>
                    <li>Вид продукции: изготовленная из полиэтилена высокого давления, светостабилизированная,
                        светотрансформирующаяся, светоудерживающая, ПВХ, армированная, воздушно-пузырчатая;
                    </li>
                    <li>Плотность. Чем плотнее полотно, тем лучше оно справляется с негативными факторами окружающей
                        среды. Соответственно для максимальной защиты растений в теплице следует выбирать изделия
                        толщиной от 150 до 200 мкм;
                    </li>
                    <li>Цвет. Прозрачный материал пропускает гораздо больше естественного света. Цветные полотна быстро
                        выгорают под солнечными лучами, а светопроницаемость у них остается невысокой.
                    </li>
                </ul>
                <p>Полиэтиленовая пленка в рулонах применяется в качестве укрывного материала для защиты растений,
                    мебели, техники, различных поверхностей от влаги и пыли. Благодаря трехслойной структуре,
                    армированная пленка имеет гораздо больший спектр применения. В частности, используется для
                    изготовления теплиц, в качестве тентов или основания под бассейн, водоем в строительстве, а также
                    для решения ряда задач в области ландшафтного дизайна.</p>
            </div>;
            break;
        case '/catalog/polivochnye-aksessuary':
            result = <div className={'seoData'}>
                <h2>Садовые инструменты для полива: лейки, опрыскиватели и пульверизаторы</h2>
                <p>В компании «Универсал» вы можете купить садовые опрыскиватели и другие поливочные аксессуары. К ним
                    относятся предметы и оборудование, позволяющие без особых усилий поддерживать необходимую влажность
                    почвы. В нашем магазине вы найдете все самое необходимое для дома, дачи и гаража по конкурентным
                    ценам.</p>
                <h3>Виды поливочного оборудования</h3>
                <p>В перечень поливочного оборудования, предложенного в каталоге, входят:</p>
                <ul>
                    <li>Лейки садовые, предназначенные для полива небольших по площади грядок, почвы вокруг саженцев и
                        кустов, плодовых и декоративных деревьев. Это емкости вместимостью от 3 до 10 литров,
                        изготовленные из прочного, устойчивого к негативным факторам внешней среды пластика;
                    </li>
                    <li>Лейки для комнатных растений. Миниатюрный поливочный инструмент, предназначенный для ухода за
                        комнатными растениями. Характерная особенность таких изделий – длинный, тонкий носик, при помощи
                        которого можно легко полить цветок, не разлив при этом воду и не повредив нежную листву
                        растения;
                    </li>
                    <li>Распылители для полива огорода, теплицы, газона. Приспособления предназначены для орошения
                        участка и поддержания нормального уровня влаги для растений, препятствуют образованию корки на
                        поверхности почвы;
                    </li>
                    <li>Муфты и переходники для шлангов – соединительные элементы, самые удобные из которых оснащены
                        быстросъемными устройствами. В нашем каталоге представлены в разных модификациях.
                    </li>
                </ul>
                <p>Кроме леек для огорода и дома, вы можете купить у нас пульверизаторы для опрыскивания комнатных и
                    уличных растений. Благодаря тому, что вода распыляется мелкими каплями, растения сложно
                    переувлажнить. Пульверизаторы также применяются для разбрызгивания жидких составов удобрений,
                    листовых подкормок и т.д.</p>
            </div>;
            break;
        case '/catalog/shlang-polivochnyj':
            result = <div className={'seoData'}>
                <h2>Поливочные шланги для воды</h2>
                <p>Простые и армированные шланги – приспособления, использующиеся для полива, перекачивания, а в
                    некоторых случаях и для подведения воды к дому. Подключаются к выходному отверстию насоса либо
                    разъему колонки водоснабжения.</p>
                <p>В каталоге товаров компании «Универсал» в ассортименте представлено несколько разновидностей
                    поливочных шлангов:</p>
                <ul>
                    <li>наборы для капельного орошения почвы, укомплектованные рукавами разной длины и инструкцией по
                        применению;
                    </li>
                    <li>армированные, пластиковые и резиновые поливочные шланги;</li>
                    <li>растягивающиеся шланги – двухслойные изделия, способные под напором воды увеличиваться в длину в
                        несколько раз.
                    </li>
                </ul>
                <p>У нас вы можете купить поливочные шланги и другие аксессуары для садово-огородных работ оптом по
                    умеренным ценам со склада в г. Томск. Условия оплаты и доставки за пределы города обсуждаются
                    индивидуально.</p>
            </div>;
            break;
        case '/catalog/lopaty':
            result = <div className={'seoData'}>
                <h2>Штыковые и совковые лопаты оптом и в розницу</h2>
                <p>В интернет-магазине «Универсал» вы можете купить лопаты штыковые и совковые для выполнения многих
                    видов работ. Мы предлагаем своим покупателям качественный садовый и рабочий инвентарь по низким
                    ценам.</p>
                <p>В каталоге вы найдете совковые лопаты для строительных и садовых работ с черенками и без них.
                    Деревянные черенки можно выбрать отдельно. Вы также можете приобрести лопаты для огорода со штыками,
                    изготовленными из прочной рельсовой стали. При необходимости можно докупить черенки для лопат разной
                    длины.</p>
                <p>Выбранные товары можно оплатить удобным для вас способом. Доставку осуществляем по удобному вам
                    адресу или до терминала выбранной вами транспортной компании. При желании можно воспользоваться
                    самовывозом с нашего склада.</p>
            </div>;
            break;
        case '/catalog/sekatory':
            result = <div className={'seoData'}>
                <h2>Садовые секаторы</h2>
                <p>В интернет-магазине «Универсал» вы можете купить оптом и в розницу секаторы для садовых работ. В
                    каталоге представлены модели разных производителей по низким ценам.</p>
                <p>Секаторы с узкими носиками предназначены для работы с мягкими ростками деревьев, цветами или
                    виноградными лозами. Модели инструмента с широким режущим кончиком используются для весенней обрезки
                    растений.</p>
                <p>Доставка осуществляется по Томску и Томской области. Мы можем доставить ваш заказ до терминала
                    транспортной компании. При желании вы можете воспользоваться самовывозом со склада в Томске.</p>
            </div>;
            break;
        case '/catalog/sadovo-ogorodnyj-inventar':
            result = <div className={'seoData'}>
                <h2>Назначение и виды садово-огородного инструмента</h2>
                <p>В компании «Универсал» вы можете купить садовый инвентарь для любых работ с почвой и растениями, а
                    также уборки прилегающей к дому территории. У любого предмета, представленного в каталоге, свое
                    назначение, каждый решает определенную задачу, облегчая труд садовода-огородника на земле.
                    Сельскохозяйственные инструменты, представленные в нашем магазине, выполнены из качественных
                    материалов.</p>
                <h3>Виды инструментов по назначению</h3>
                <p>В категорию изделий для обработки почвы входят:</p>
                <ul>
                    <li>лопаты двух типов – штыковые, совковые;</li>
                    <li>вилы, предназначенные для вскапывания легких почв, рыхления, удаления глубоких корней
                        растений;
                    </li>
                    <li>мотыги – инструменты для устранения сорняков с грядок.</li>
                </ul>
                <p>Также вы можете купить обычные грабли для обработки вскопанной земли, рыхления или веерные –
                    предназначенные для сбора опавшей листвы, веток и мусора.</p>
                <p>Для работы с деревьями, кустарниками, стрижки газона предназначен следующий инвентарь:</p>
                <ul>
                    <li>ножницы, сучкорезы, секаторы, ножовки – инструменты, использующиеся для обрезки кустов,
                        деревьев;
                    </li>
                    <li>подручные инструменты для уборки урожая – плодосъемники, фартуки, специальные емкости и т.д.;
                    </li>
                    <li>триммеры с запасными лесками разной длины и диаметра.</li>
                </ul>
                <p>Также для выкашивания небольших куртин травы в местах, к которым нет доступа для газонокосилки, вы
                    можете купить серпы – небольшой режущий инструмент с изогнутым острым лезвием.</p>
                <p>Купить вилы, грабли, лопаты и другие садово-огородные приспособления можно по отдельности или, собрав
                    в комплект изделия одного производителя, соответствующие требованиям к качеству и надежности.</p>
            </div>;
            break;
        case '/catalog/tachki-telezhki':
            result = <div className={'seoData'}>
                <h2>Тачки для сада и тележки для хозяйственных нужд</h2>
                <p>Собираясь купить садовую тачку или тележку в компании «Универсал» Томска, обратите внимание на
                    характеристики моделей разных производителей, представленных в каталоге.</p>
                <h3>Виды садовых тачек</h3>
                <p>В зависимости от конструкционных особенностей тачки подразделяются на два вида:</p>
                <ul>
                    <li>Одноколесные – быстрые, маневренные, хорошо приспособленные для перемещения по пересеченной
                        местности или в условиях ограниченного пространства;
                    </li>
                    <li>Двухколесные – гораздо устойчивее и грузоподъемнее одноколесных, однако менее маневренные и
                        управляемые.
                    </li>
                </ul>
                <p>У нас вы можете купить тачки садовые и запасные колеса к ним для работы в саду и огороде, выбрав
                    подходящий вариант в зависимости от грузоподъемности, количества колес, объема чаши.</p>
                <h3>Основные правила выбора сумки-тележки</h3>
                <p>Для садоводов и огородников важно облегчить транспортировку рассады и удобрений из города на дачу или
                    урожая обратно в город. Самый простой выход из положения – купить хозяйственную сумку-тележку для
                    перевозки ручной клади. Они вместительны, снабжены прочными колесиками, эргономичными выдвижными
                    ручками, различны по весу.</p>
                <p>При выборе сумки-тележки обратите внимание на ряд параметров:</p>
                <ul>
                    <li>объем кузова или чаши;</li>
                    <li>грузоподъемность;</li>
                    <li>форма и длина ручек;</li>
                    <li>общие конструктивные особенности – рисунок рамы, диаметр несущих каркас труб, вес изделия.</li>
                </ul>
                <p>Сумки-тележки выполняют функции дорожной сумки, чемодана и тележки одновременно. Для удобства вы
                    можете купить тележку, оснащенную большим количеством функциональных кармашков. Дополнительные
                    крепежные элементы для транспортировки инвентаря, емкостей можно приобрести отдельно.</p>
            </div>;
            break;
        case '/catalog/grunty-udobreniya-i-biopreparaty':
            result = <div className={'seoData'}>
                <h2>Грунты и удобрения для круглогодичного выращивания растений</h2>
                <p>В компании «Универсал» вы можете купить грунт и удобрения оптом и в розницу для любых типов растений.
                    Грунты – многокомпонентные смеси, обладающие оптимальным уровнем кислотности, со сбалансированным по
                    микро- и макроэлементному содержанием. Удобрения – питательные составы, с помощью которых растениям
                    поставляются компоненты, необходимые для нормального роста и развития.</p>
                <p>В каталоге вы можете найти:</p>
                <ul>
                    <li>грунт универсальный, землю для определенного или любого типа рассады;</li>
                    <li>удобрения гранулированные, жидкие, порошковые для деревьев, цветов, плодоносящих и декоративных
                        растений, газонной травы, одно- и многокомпонентные;
                    </li>
                    <li>грунт цветочный: торфяной, торфогрунтовый, для роз, орхидей, лилий и т.д.</li>
                </ul>
                <p>Почвенные смеси используются для круглогодичного выращивания растений. В зависимости от назначения
                    содержат песок, гуматы, низинный торф, микроэлементы и витаминизированные добавки.</p>
                <p>Заказ вы можете оформить на сайте, оплатив любым удобным способом. Доставка бесплатна при сумме
                    заказа от 3000 рублей. Возможен самовывоз со склада в Томске.</p>
            </div>;
            break;
        case '/catalog/odeyalo-podushki-matrasy-pokryvalo':
            result = <div className={'seoData'}>
                <h2>Недорогие и качественные одеяла и ватные матрасы</h2>
                <p>В интернет-магазине «Универсал» вы можете купить оптом и в розницу одеяла и ватные матрасы по самой
                    доступной в Томске цене. Мы сотрудничаем только с надежными производителями, дающими гарантию на
                    свои товары. В каталоге вы найдете постельные принадлежности разных размеров и расцветок.</p>
                <h3>Особенности одеял и матрасов</h3>
                <p>Мы предлагаем своим покупателям синтепоновые одеяла разных размеров и толщины. В карточках товаров
                    указаны цены и основные характеристики, а также рекомендации по уходу за одеялами.</p>
                <p>Также у нас вы можете купить стандартный ватный матрас, популярный еще с советских времен. К
                    преимуществам ватных матрасов можно отнести:</p>
                <ul>
                    <li>натуральный наполнитель;</li>
                    <li>небольшой вес;</li>
                    <li>компактность изделия в сложенном состоянии;</li>
                    <li>возможность высушить, разложив на солнце;</li>
                    <li>высокая прочность и продолжительный срок службы.</li>
                </ul>
                <p>У нас вы можете выбрать изделие в зависимости от размеров: 190х120 см, 190х160 см, 190х80 см, 190х90
                    см.</p>
            </div>;
            break;
        case '/catalog/komplekty-postelnogo-belya-i-izdeliya':
            result = <div className={'seoData'}>
                <h2>Комплекты постельного белья из бязи и других натуральных материалов</h2>
                <p>В оптово-розничном магазине «Универсал» вы можете купить постельное белье оптом от производителя по
                    низким ценам. Мы предлагаем на выбор комплекты, изготовленные из качественных, натуральных тканей,
                    представленные в разных размерах и расцветках. Универсальное белье может быть белого цвета или
                    пастельных оттенков, синий и фиолетовый создадут гармоничную атмосферу в спальне, розовый –
                    романтичную, желтый или оранжевый больше подходят для детских.</p>
                <h3>Выбор комплектов постельного белья</h3>
                <p>У нас вы можете заказать постельное белье оптом, выбрав подходящие варианты из списка
                    представленных:</p>
                <ul>
                    <li>полутораспальные;</li>
                    <li>двуспальные;</li>
                    <li>евро-стандарт;</li>
                    <li>семейное;</li>
                    <li>детское.</li>
                </ul>
                <p>Также при выборе следует обратить внимание на комплектацию. По стандарту в комплект входят
                    пододеяльник, простынь и две наволочки. В магазине «Универсал» вы сможете купить постельное белье из
                    бязи, хлопка, поплина или других материалов. Если вы не нашли КПБ подходящих размеров и
                    комплектации, можете заказать комплект по индивидуальным размерам. Срок пошива составляет 2-3
                    рабочих дня. У нас можно оформить доставку заказа по Томску, области или до терминала транспортной
                    компании. Возможен самовывоз.</p>
            </div>;
            break;
        case '/catalog/dlya-piknika-i-otdyha':
            result = <div className={'seoData'}>
                <h2>Товары для отдыха на природе</h2>
                <p>В магазине «Универсал» Томска вы можете купить оптом все необходимое для пикника – шампуры, жидкость
                    для розжига, древесный уголь для мангала, складную мебель и другие принадлежности. В нашем каталоге
                    представлены изделия в разных ценовых категориях.</p>
                <h3>Особенности шампуров</h3>
                <p>Технология приготовления шашлыка подразумевает постоянный контакт металла и мясных кусочков.
                    Выделяемый сок, высокие температуры и воздействие кислот из маринада весьма агрессивно воздействуют
                    на материал. Поэтому идеальным металлом для приготовления этого блюда считается нержавеющая сталь. У
                    нас в каталоге представлены шампуры следующих видов:</p>
                <ul>
                    <li>плоские: 410х10х1,5 мм;</li>
                    <li>уголковые: 450х10х1 мм, 500х10х1 мм.</li>
                </ul>
                <p>Также вы можете сразу выбрать и купить средство для розжига и деревянные шампуры для одноразового
                    использования. На палочках удобно готовить шампиньоны, кусочки овощей или поджаривать на огне
                    зефир.</p>
                <h3>Складная мебель для пикника</h3>
                <p>Поход на пикник или рыбалку должен запомниться не только вкусной едой, но и комфортным
                    времяпрепровождением. Для этого необходима удобная складная мебель. У нас вы можете выбрать:</p>
                <ul>
                    <li>Складные стулья – компактная туристическая мебель, в сложенном виде занимающая минимум места в
                        багажнике автомобиля или в шкафу. В разложенном представляет собой классический стул со спинкой
                        или небольшую табуретку, на которой удобно развести огонь, пообедать или порыбачить;
                    </li>
                    <li>Кресло складное – усовершенствованный и более комфортный походный стул. Оснащается широкими
                        сидениями и спинками, функциональными элементами в виде карманов, ниш, держателей.
                    </li>
                </ul>
                <p>В оптово-розничном магазине «Универсал» можно купить складные стулья и кресла оптом и в розницу по
                    цене, рекомендованной производителями. Выбор стульев или складных туристических кресел следует
                    строить, исходя из допустимой нагрузки, надежности механизмов соединения, материала изготовления,
                    места и времени использования. Одни кресла подходят для пикника, другие больше подходят для
                    рыбалки.</p>
            </div>;
            break;
        case '/catalog/sredstva-zashity-ot-vreditelej':
            result = <div className={'seoData'}>
                <h2>Инсектициды: средства от муравьев, колорадского жука и других вредителей</h2>
                <p>Инсектициды – вещества, предназначенные для уничтожения насекомых. Обработка средствами от муравьев и
                    других вредителей входит в перечень обязательных мероприятий по уходу за растениями. В
                    оптово-розничном магазине «Универсал» вы можете купить натуральный березовый деготь или комплексные
                    химические средства для борьбы с вредителями.</p>
                <h3>Продукция каталога</h3>
                <p>В нашем каталоге представлены средства для борьбы с насекомыми-вредителями разных видов:</p>
                <ul>
                    <li>средства от колорадского жука: Актара, Инта-Вир, Командор и другие;</li>
                    <li>от муравьев – ГРОМ-2;</li>
                    <li>средства от тли – Бордоская смесь;</li>
                    <li>универсальные средства, самое популярное из которых Бартрайдер.</li>
                </ul>
                <p>Каждый из препаратов проверен на безопасность в отношении организма человека, имеет собственный
                    период распада, по прошествии которого плоды растений можно употреблять в пищу. Однако при работе с
                    любым из них требуются особые меры предосторожности. Перед применением следует внимательно изучить
                    инструкцию производителя.</p>
                <p>Все средства от вредителей, представленные в нашем каталоге, имеют продолжительный срок годности,
                    реализуются по низкой цене, рекомендованной производителем.</p>
            </div>;
            break;
        case '/catalog/agroteks':
            result = <div className={'seoData'}>
                <h2>Укрывные материалы крупным и мелким оптом</h2>
                <p>В оптово-розничном магазине «Универсал» вы можете купить укрывной материал для садово-огородных
                    культур. Он используется для защиты растений от отрицательного воздействия факторов окружающей
                    среды, насекомых и птиц, возбудителей заболеваний, препятствующих нормальному росту, развитию и
                    плодоношению. Сегодня садоводы-любители предпочитают пользоваться неткаными укрывными полотнами,
                    которые при невысокой цене обладают массой положительных характеристик.</p>
                <h3>Преимущества нетканого укрывного материала</h3>
                <p>В нашем каталоге в зависимости от назначения вы можете выбрать и купить «Агротекс» разной толщины и
                    размеров полотен. К основным преимуществам материалов относятся:</p>
                <ul>
                    <li>универсальность – возможность использования для обустройства парников на металлическом и
                        пластиковом каркасе;
                    </li>
                    <li>мягкость, легкость, отсутствие травмоопасности при соприкосновении с растениями;</li>
                    <li>воздухо-, паро- и влагопроницаемость, возможность осуществлять полив рассады, не открывая
                        парник;
                    </li>
                    <li>абсолютная экологическая безопасность для людей, растений, почвы;</li>
                    <li>простота в применении, продолжительный срок службы, ремонтопригодность.</li>
                </ul>
                <p>У нас вы можете купить укрывные материалы крупным и мелким оптом, расплатившись за заказ любым
                    удобным способом. Доставка осуществляется по Томской области, в другие регионы – до транспортной
                    компании. Возможен самовывоз со склада в Томске.</p>
            </div>;
            break;
        default:
            result = null;
    }
    return result;
}

export function getMetaForPath(path) {
    let result = null;
    switch (path) {
        case '/catalog/samorezy':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Саморезы купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Саморезы купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить саморезы по дереву, металлу, универсальные по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить саморезы по дереву, металлу, универсальные по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/parniki':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Парники купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Парники купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить парники для рассады по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить парники для рассады по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/plenka-polietilen':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Купить пленку для теплицы оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Купить пленку для теплицы оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить армированную полиэтиленовую пленку для теплиц по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Доставка 0 руб. при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить армированную полиэтиленовую пленку для теплиц по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Доставка 0 руб. при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/polivochnye-aksessuary':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Опрыскиватели, лейки купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Опрыскиватели, лейки купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить садовые лейки и опрыскиватели по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить садовые лейки и опрыскиватели по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/shlang-polivochnyj':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Шланги поливочные купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Шланги поливочные купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить армированные шланги поливочные по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить армированные шланги поливочные по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/lopaty':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Лопаты для огорода купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Лопаты для огорода купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить штыковые и совковые лопаты по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить штыковые и совковые лопаты по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/sekatory':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Секаторы купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Секаторы купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить секаторы по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить секаторы по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/sadovo-ogorodnyj-inventar':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Грабли, вилы, серпы купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Грабли, вилы, серпы купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить садовый инвентарь (грабли, серпы, вилы, лески для триммера) по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз в Томске. Доставка 0 руб. при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить садовый инвентарь (грабли, серпы, вилы, лески для триммера) по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз в Томске. Доставка 0 руб. при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/tachki-telezhki':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Тачки, сумки-тележки купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Тачки, сумки-тележки купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить садовые тачки и колеса для них, сумки-тележки по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить садовые тачки и колеса для них, сумки-тележки по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/grunty-udobreniya-i-biopreparaty':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Грунты, удобрения купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Грунты, удобрения купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить удобрения, биопрепараты, грунт универсальный и цветочный по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз в Томске. Доставка 0 руб. при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить удобрения, биопрепараты, грунт универсальный и цветочный по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз в Томске. Доставка 0 руб. при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/odeyalo-podushki-matrasy-pokryvalo':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Одеяло, подушки, матрасы, покрывало купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title"
                      content="Одеяло, подушки, матрасы, покрывало купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить одеяло, подушки (пух-перо, синтепон, бамбук), ватные матрасы, покрывало по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить одеяло, подушки (пух-перо, синтепон, бамбук), ватные матрасы, покрывало по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/komplekty-postelnogo-belya-i-izdeliya':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Постельное белье купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Постельное белье купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить постельное белье (бязь, поплин, сатин, полиэстер) по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить постельное белье (бязь, поплин, сатин, полиэстер) по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/dlya-piknika-i-otdyha':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Шампуры, уголь, складные стулья купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title"
                      content="Шампуры, уголь, складные стулья купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить товары для пикника и отдыха: шампуры, уголь, жидкость для розжига, мангал, складные стулья-кресла – по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз. Доставка 0 руб."/>
                <meta property="og:description"
                      content="Купить товары для пикника и отдыха: шампуры, уголь, жидкость для розжига, мангал, складные стулья-кресла – по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз. Доставка 0 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/sredstva-zashity-ot-vreditelej':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Средство от насекомых-вредителей купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title"
                      content="Средство от насекомых-вредителей купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить средства от насекомых-вредителей (муравьев, тли, колорадского жука) по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз. Доставка 0 руб. при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить средства от насекомых-вредителей (муравьев, тли, колорадского жука) по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз. Доставка 0 руб. при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/agroteks':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Укрывной материал Агротекс купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Укрывной материал Агротекс купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить укрывной материал Агротекс по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить укрывной материал Агротекс по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <meta property="og:url" content="https://universal.tom.ru/"/>
                <title>Хозтовары купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Хозтовары купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить строительно-хозяйственные товары по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить строительно-хозяйственные товары по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
            </Helmet>;
            break;
        case '/catalog/bytovaya-himiya':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Бытовая химия купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Бытовая химия купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить бытовую химию по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить бытовую химию по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/gubki-mochalki-salfetki-tryapki':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Мочалки, губки, салфетки, тряпки купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title"
                      content="Мочалки, губки, салфетки, тряпки купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить губки, тряпки, салфетки, мочалки по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить губки, тряпки, салфетки, мочалки по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/dlya-doma':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Товары для дома купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Товары для дома купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить товары для дома по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить товары для дома по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/zamki':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Замки на дверь купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Замки на дверь купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить дверные замки по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить дверные замки по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/zimnij-inventar':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Лопаты, ледорубы купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Лопаты, ледорубы купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить инвентарь для очистки, уборки от снега и наледи, льда с по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз в г. Томск. Доставка 0 руб. при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить инвентарь для очистки, уборки от снега и наледи, льда с по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз в г. Томск. Доставка 0 руб. при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/instrument':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Инструменты купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Инструменты купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить строительные инструменты по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить строительные инструменты по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/kanaty-shnury':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Шнур, канат, веревка, шпагат купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title"
                      content="Шнур, канат, веревка, шпагат купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить шнуры, веревки бельевые, канаты, бечевки, шпагат по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз в г. Томск. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить шнуры, веревки бельевые, канаты, бечевки, шпагат по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз в г. Томск. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/kanctovary-i-fotoramki':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Канцтовары купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Канцтовары купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить канцтовары, фоторамки по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить канцтовары, фоторамки по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/kisti-valiki-vannochki-dlya-kraski':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Валики, кисти купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Валики, кисти купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить малярные кисти, валики, ванночки по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить малярные кисти, валики, ванночки по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/kleenka-stolovaya':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Клеенку на стол купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Клеенку на стол купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить столовую клеенку по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить столовую клеенку по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/klej-germetiki':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Клей, герметик купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Клей, герметик купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить герметики, клей по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить герметики, клей по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/kraski-laki-gruntovki-rastvoriteli':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Лакокрасочные материалы купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Лакокрасочные материалы купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить лакокрасочные материалы по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить лакокрасочные материалы по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/metizy':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Метизы купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title" content="Метизы купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить метизы по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить метизы по оптовой цене в оптово-розничном интернет-магазине «Универсал». Самовывоз со склада в Томске. Бесплатная доставка при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        case '/catalog/skotch-lenta-serpyanka-binty-stroitelnye':
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Скотч, лента, изолента, серпянка купить оптом в Томске, цены | Универсал</title>
                <meta property="og:title"
                      content="Скотч, лента, изолента, серпянка купить оптом в Томске, цены | Универсал"/>
                <meta name="description"
                      content="Купить серпянку, изоленту, малярную ленту, двухсторонний скотч и ленту по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз в Томске. Доставка 0 руб. при заказе от 3000 руб."/>
                <meta property="og:description"
                      content="Купить серпянку, изоленту, малярную ленту, двухсторонний скотч и ленту по оптовой цене в оптово-розничном магазине «Универсал». Самовывоз в Томске. Доставка 0 руб. при заказе от 3000 руб."/>

                <meta property="og:url" content="https://universal.tom.ru/catalog/*"/>
                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
            break;
        default:
            result = <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="theme-color" content="#000000"/>
                <title>Универсал</title>
                <meta property="og:title" content="Каталог"/>
                <meta name="description"
                      content="Товары для дома, хозяйственные товары, спец. одежда и многое другое!"/>
                <meta property="og:description"
                      content="Множество товаров для дома, хозяйства, авто и многого другого!"/>

                <link rel="canonical" href={window && window.location && window.location.origin + window.location.pathname}/>
            </Helmet>;
    }
    return result;
}